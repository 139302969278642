import styles from '@styles/common-scss/_breadcrumbsNav.module.scss';
import Link from 'next/link';
import React, { FC } from 'react';

import { getCapitalize, getSlug } from '../../../lib/web/meta';

interface BreadcrumbsNavProps {
    type?: string;
    disablePrimary?: boolean;
    forcePrimary?: string;
    primary?: string;
    secondary?: string;
    style?: string;
    disableSecondary?: boolean;
}

const BreadcrumbsNav: FC<BreadcrumbsNavProps> = ({
    type = '',
    primary = '',
    secondary = '',
    forcePrimary = '',
    style = '',
    disablePrimary = false,
    disableSecondary = false
}) => {
    return (
        <div className={`${styles.wrapper} ${style && styles[style]}`}>
            <Link href={`/`}>
                <a>Home</a>
            </Link>
            {type === 'reviews/' && (
                <>
                    {' / '}
                    <Link href={`/porn/categories`}>
                        <a>{getCapitalize('categories')}</a>
                    </Link>
                </>
            )}
            {primary && !disablePrimary && (
                <>
                    {' / '}
                    <Link href={`/porn/${getSlug(primary)}`}>
                        <a>{getCapitalize(primary)}</a>
                    </Link>
                </>
            )}
            {forcePrimary && (
                <>
                    {' / '}
                    {forcePrimary === 'deals' ? (
                        <Link href="/porn/deals">
                            <a>Porn Deals</a>
                        </Link>
                    ) : (
                        <Link href={`/porn/categories/${getSlug(forcePrimary)}`}>
                            <a>{getCapitalize(forcePrimary)}</a>
                        </Link>
                    )}
                </>
            )}
            {secondary && !disableSecondary && (
                <>
                    {' / '}
                    <a>{`Best ${getCapitalize(secondary)} Porn Sites`}</a>
                </>
            )}
        </div>
    );
};

export default BreadcrumbsNav;
